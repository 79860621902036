<template>
  <div class="caixa_cp col-md-12 px-0 my-3">

    <div
      class="row align-items-center"
      :class="columColor(item.qtdNota)"
      v-for="(item ,index) in notas"
      :key="index"
    >
      <div class="col-md-4 text-center py-1">
          <img :src="getDinheiroImg(item.valorNota)" class="notas" :style="item.id === 9 ? 'width: 66px;': 'width: 100%;'" />
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4 text-center input_camp py-1">
          <div class="d-flex justify-content-center align-items-center espaco">
            <div
              v-if="item.valorNota >= 0"
              class="icon_div d-flex justify-content-center align-items-center"
              @click.prevent="getNewvalue(item.qtdNota > 0 ? item.qtdNota - 1 : 0, index)"
            >
              <i class="fas fa-minus"></i>
            </div>
            <input
            v-if="item.valorNota >= 0"
              type="number"
              class="inputValue mx-2"
              :value="item.valor ? item.valor : item.qtdNota"
              min="0" step="0.01"
              @input="getNewvalue(parseInt($event.target.value), index)"
            />
            <input
            v-else
              type="text"
              class="inputValue mx-2 disableds"
              :value="item.qtdNota || valueConvertBR(item.valor)"
              disabled
              minlength="0"
               @input="getNewvalue(parseFloat($event.target.value), index)"
            />
            <div
              v-if="item.valorNota >= 0"
              class="icon_div d-flex justify-content-center align-items-center"
              @click.prevent="getNewvalue(item.qtdNota + 1, index)"
            >
              <i class="fas fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalValue', 'qtdImpress'],
  data () {
    return {

      notas: [
        {
          valorNota: -1,
          valor: 0,
          id: 13
        },
        {
          valorNota: -2,
          valor: 0,
          id: 12
        },
        {
          valorNota: 200,
          qtdNota: 0,
          id: 1
        },
        {
          valorNota: 100,
          qtdNota: 0,
          id: 1
        },
        {
          valorNota: 50,
          qtdNota: 0,
          id: 2
        },
        {
          valorNota: 20,
          qtdNota: 0,
          id: 3
        },
        {
          valorNota: 10,
          qtdNota: 0,
          id: 4
        },
        {
          valorNota: 5,
          qtdNota: 0,
          id: 5
        },
        {
          valorNota: 2,
          qtdNota: 0,
          id: 6
        },
        {
          valorNota: 1,
          qtdNota: 0,
          id: 7
        },

        {
          valorNota: 0.5,
          qtdNota: 0,
          id: 8
        },
        {
          valorNota: 0.25,
          qtdNota: 0,
          id: 9
        },
        {
          valorNota: 0.1,
          qtdNota: 0,
          id: 10
        },
        {
          valorNota: 0.05,
          qtdNota: 0,
          id: 11
        }
      ],
      resetNotas: '',
      resultPays: 0
    }
  },
  mounted () {
    let self = this

    this.resetNotas = Object.assign({}, this.notas)

    // eslint-disable-next-line no-undef
    $('#modalCaixa').on('hide.bs.modal', function (e) {
      self.$emit('update:totalValue', 0)
      Object.assign(self.$data, self.$options.data())
    })
  },
  methods: {
    columColor (val) {
      let classe
      if (val > 0) {
        classe = 'value_colum'
      } else {
        classe = 'no_value_colum'
      }
      return classe
    },
    getNewvalue (val, index) {
      this.notas[index].qtdNota = val
      var total = this.notas.reduce(function (total, notas) {
        if (isNaN(notas.qtdNota)) {
          notas.qtdNota = 0
        }
        return notas.valorNota * notas.qtdNota + total
      }, 0)

      total = total + this.notas[0].qtdNota

      this.$emit('update:totalValue', total + this.resultPays)

      // this.$emit("update:totalValue", total + this.resultPays); som com valores de cartao e pix
    },
    qtdNota (val, index) {
      // eslint-disable-next-line no-undef
      if (qtdNota != null && qtdNota !== undefined) {
        this.$emit('update:totalValue', this.qtdNota)
      }
    },
    valueSomas (e) {
      let values = []
      let valoresSoma = []

      e.forEach(item => {
        values.push(item)
      })

      this.notas.forEach(item => {
        if (item.id === 12) {
          item.valor = values[0].valuePayment
          valoresSoma.push(values[0].valuePayment)
        }
        if (item.id === 13) {
          item.valor = values[1].valuePayment
          valoresSoma.push(values[1].valuePayment)
        }
      })

      let result = valoresSoma.reduce((value, index) => {
        return value + index
      }, 0)

      this.resultPays = result

      return result
    }
  },
  watch: {
    notas: function () {},
    '$store.getters.valuePays': function (e) {
      this.valueSomas(e)
    }
  }
}
</script>

<style scoped>
.input_camp {
  background: #2474b2;
}
.inputValue {
  font-weight: 500;
  width: 50px;
  height: 50px;
  background-color: #6198c2;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
}

.value_colum {
  background-color: #175d93;
  border: 1px solid #ffffff;
}
.no_value_colum {
  background-color: #e3e3e3;
  border: 1px solid #ffffff;
}
.value_colum .input_camp {
  background: #2474b2;
}
.no_value_colum .input_camp {
  background: #868686;
}
.icon_div {
  background-color: #ffffff;
  height: 20px;
  display: flex;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
}
.icon_div i {
  color: #2474b2;
  font-size: 10px;
}
.value_colum >>> p {
  color: #ffffff;
}
.no_value_colum >>> p {
  color: #868686;
}
.notas {
  width: 100%;
}
p {
  margin-bottom: 0;
  font-weight: 500;
}

.disableds {
  background-color: rgb(85, 80, 80);
}
@media screen and (max-width:767px) {
  .row{
    flex-wrap: unset!important;
  }
}
</style>
