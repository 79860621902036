<template>
    <div class="containerBox">
       <h1>{{service}}</h1>
       <div class="radius" :class="negative ? 'negative': ''">
           <loader v-if="task === null" color="#e0a638" size="50"/>
           <h2 v-else>{{task}}</h2>
       </div>
    </div>
</template>
<script>
import loader from "@/components/utils/_Load";
export default {
    props:{
        service: {
            type: String,
            require: true
        },
        task: {
            type: Number,
            require: true,
            default: null
        },
        negative: {
            type: Boolean,
            require: true,
            default: false,
        },
        page: 0
    },
    components: {
        loader
    }
}
</script>

<style scoped>
.containerBox {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.radius {
    height: 80px;
    background-color: #357eb8;
    box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
    width: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.negative {
  background-color: #E67979 !important;
}
h1{
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}
h2 {
    color: #fff;
    font-size: 30px;
}
@media screen and (max-width:767px){
    .containerBox{
        flex-direction: row!important;
        background: #0068b9;
        border: 1px solid #2474B2;
        border-radius: 8px;  
    }
    .containerBox h1{
        margin: 2.5%;
        max-width: 60%;
        min-width: 60%;
    }
    .radius{
        background: #D6E7F5;
        border-radius: 8px;
        height: 65px;
    }
    .radius h2{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #2474B2;
    }
}
</style>