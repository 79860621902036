<template>
<div>
    <div class="col-md-12 px-0">

        <p class="titulo_dash my-3 titleMes fontLarge" id="monthTitle" style="margin: 0 0 0 9px;">
            {{ meses[item.month - 1] }} {{ item.year }}
        </p>
    </div>
    <div class="col-md-12 containerPage">
        <div class="row">

            <div class="col-md-1 centerFlex">
                <div class="row" v-if="this.sizeWindow > 767">
                    <div class="col-md-auto">
                        <p class="titulo_dash mb-2 text-center fontLarge">Dia / Vendedor</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 text-left abertura" v-if="this.sizeWindow > 767">
                <p class="titulo_dash mb-2 fontLarge text-center">Abertura</p>
            </div>
            <div class="col-md-2 fechamento" v-if="this.sizeWindow > 767">
                <p class="titulo_dash md-8 text-center fontLarge">Fechamento</p>
            </div>

            <div class="col-md-4 " v-if="this.sizeWindow > 767">
                <div class="row">
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge" style="position: relative; left: -34px;">Vendas</p>
                    </div>
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge" style="position: relative; left: -34px;">Dinheiro</p>
                    </div>
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge" style="position: relative; left: -26px;">Cartão</p>
                    </div>
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge text-center" style="position: relative; left: -20px;">Pix / Transf</p>
                    </div>
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge" style="position: relative; left: -25px;">Retirada</p>
                    </div>
                    <div class="col-md-2 ">
                        <p class="titulo_dash mb-1 fontLarge" style="position: relative; left: -10px;">Saldo Caixa</p>
                    </div>
                </div>

            </div>

            <div class="col-md-2 align-items-center diferenca" v-if="this.sizeWindow > 767">
                <p class="titulo_dash mb-2 fontLarge" style="    position: relative; left: -24px;">Diferença</p>
            </div>
        </div>

        <div class="col-md-12 mb-3 px-0" v-for="(itens, index) in item.listCashier" :key="index">
            <div class="container-xxl">
                <div class="row">
                    <div class="col-md-1 text-center px-1">
                        <div class="box">
                            <div class="d-flex flex-column h-100 align-items-center justify-content-center">
                                <div class="col-md-auto mb-2">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Dia:</label>
                                    <p class="titulo_dash mb-0 titleMesF1">{{ itens.day }}</p>
                                </div>
                                <div class="col-md-auto">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Usuário:</label>
                                    <p class="titulo_dash mb-0">{{ itens.user }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 text-center px-1" :style="sizeWindow > 767 ?'max-width: 14%;' : 'max-width: 100%;'">
                        <div class="box">
                            <div class="d-flex h-100 align-items-center">
                                <div class="col-md-3">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Abertura:</label>
                                    <p class="titulo_dash mb-0">
                                        {{ itens.openingTime.substring(0, 5) }}
                                    </p>
                                </div>
                                <div class="col-md-4 mx-auto">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Abertura:</label>
                                    <p class="titulo_dash mb-0">
                                        {{ itens.inicialValue | currency }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 text-center px-1 mr-3" id="boxInfoCont">
                        <div class="box">
                            <div v-if="itens.endValue" class="d-flex h-100 align-items-center">
                                <div class="col-md-3">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Fechamento:</label>
                                    <p class="titulo_dash mb-0">
                                        {{ itens.closingTime.substring(0, 5) }}
                                    </p>
                                </div>
                                <div class="col-md-4 mx-auto">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Fechamento:</label>
                                    <p class="titulo_dash mb-0">
                                        {{ itens.endValue | currency }}
                                    </p>
                                </div>
                            </div>
                            <div v-else class="col-md-8 mx-auto d-flex h-100 align-items-center">
                                <button class="btn_default" @click.prevent="openModal(itens.id)" v-if="$store.getters.getUser.profile === 2 ">
                                    Fechar o caixa
                                </button>
                                <button class="btn_default" @click.prevent="openModal(itens.id)" v-if="$store.getters.getUser.profile === 3 && !itens.isActualCashier">
                                    Fechar o caixa
                                </button>
                                <p v-else-if="$store.getters.getUser.profile === 3 && itens.isActualCashier" class="titulo_dash mb-0 w-100 text-center">
                                    Caixa atual
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 text-center px-0 ml-0 mr-3" id="rowFlexCont">

                        <div class="rowFlex">
                            <div class="box col-md-2 mr-1 centersContainer  ">
                                <div class="d-flex h-100 align-items-center mx-auto d-flex h-100 align-items-center centers">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Vendas:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767">{{ valueConvertBR(itens.moviment)}} </p>
                                </div>
                            </div>

                            <div class="box col-md-2 mr-1 centersContainer ">
                                <div class="d-flex h-100 align-items-center infoValueCont ">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Dinheiro:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767"> {{valueConvertBR(itens.paymentValues[0].valuePayment)}}</p>
                                </div>
                            </div>
                            <div class="box col-md-2 mr-1 centersContainer ">
                                <div class="d-flex h-100 align-items-center infoValueCont ">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Cartão:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767"> {{valueConvertBR(itens.paymentValues[1].valuePayment)}}</p>
                                </div>
                            </div>
                            <div class="box col-md-2 mr-1 centersContainer ">
                                <div class="d-flex h-100 align-items-center infoValueCont ">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Pix/Transf:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767"> {{valueConvertBR(itens.paymentValues[2].valuePayment)}}</p>
                                </div>
                            </div>
                            <div class="box col-md-2 mr-1 centersContainer ">
                                <div class="d-flex h-100 align-items-center infoValueCont ">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Retirada:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767"> {{valueConvertBR(!itens.withdrawal ? 0: itens.withdrawal)}}</p>
                                </div>
                            </div>
                            <div class="box col-md-2 mr-1 centersContainer">
                                <div class="d-flex h-100 align-items-center infoValueCont text-center ">
                                    <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMes">Saldo Caixa:</label>
                                    <p class="titulo_dash mb-0 text-center"> R$ <br v-if="sizeWindow > 767"> {{ valueConvertBR(itens.balance) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="row" id="resultRow">
                            <div class="box col-md-5 px-1 ml-1 mr-1" :style="itens.difference >= 0 ? 'background-color: #35b890;' : 'background-color: #E67979;'">
                                <div class="d-flex h-100 align-items-center infoValueCont" v-if="itens.difference != null" data-placement="right">
                                    <div class="col-md-12 text-center" id="differenceCont">
                                        <label v-if="sizeWindow <= 767" class="titulo_dash mb-0 titleMesF1">Diferença:</label>
                                        <p class="titulo_dash mb-0">
                                            {{ itens.difference | currency }}
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex h-100 align-items-center" v-else>
                                    <div class="col-md-12 text-center">
                                        <p class="titulo_dash mb-0">-</p>
                                    </div>
                                </div>
                            </div>

                            <div class="box col-md-5 px-1 ml-1 mr-1" :style="
                  itens.printsNon > 0
                    ? 'background-color: #E67979;'
                    : 'background-color: #35b890;'
                ">
                                <div class="d-flex h-100 align-items-center" v-if="itens.printsNon != null" data-placement="right" :title="itens.printsNon + ' Impressões não relacionadas'">
                                    <div class="col-md-12 text-center">
                                        <p class="titulo_dash mb-0">
                                            <strong>{{ itens.printsNon }}</strong> Imp
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex h-100 align-items-center" v-else>
                                    <div class="col-md-12 text-center">
                                        <p class="titulo_dash mb-0">
                                            <strong>-</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div :class=" maisDeSeteDiasAtras(`${item.year}-${item.month }-${itens.day}`) ? 'col-md' : 'col-md visibilityShow'">
                        <listCsvVue :id="itens.id" @showModalDegustacao="showModalDegustacao"/>
                      </div>
                </div>
            </div>
        </div>
        <modal :id.sync="id" @getParent="getParent" :pays='valuesPays' />
        <modalDegustacao/>
    </div>
</div>
</template>

<script>
import modal from './modal'
import modalDegustacao from "@/components/modal/modalDegustacao";
import HTTP from '@/api_system'
import listCsvVue from '../CSV/listCsv.vue'
import { mapActions } from 'vuex'
export default {
  props: ['item'],
  components: {
    modal,
    listCsvVue,
    modalDegustacao
  },
  data () {
    return {
      meses: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],

      id: undefined,
      valuesPays: [],
      sizeWindow: 0,
      item: true,
      data: [],
      dataJson: []
    }
  },
  created () {

  },
  mounted () {
    this.sizeWindow = $(window).width()
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    ...mapActions(['requestDataListCsv']),
    async requestItem (id, name) {
      try {
        const result = await this.requestDataListCsv(id)
        this.dataJson = result
        // const t = document.querySelector(`.${name}`)
        // const d = t.querySelector('.btn')

        // // d.click()
        // console.log(dataJson)

        // this.$refs.name
      } catch (error) {
        this.toast(
          error.response.data.error.message,
          this.$toast.error
        )
      }
    },
    calcValue (val, val1) {
      return val1 - val
    },
    openModal (val) {
      this.id = val
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        sessionStorage.setItem('idCaixa', val)
        this.valuesPay(val)
      }
      // $("#modalCaixa").modal("show");
      // console.log(this.valuesPays)
    },
    getParent () {
      this.$emit('getCaixa')
    },
    maisDeSeteDiasAtras (dia) {
      // Obter a data atual
      const dataAtual = new Date()
      // Subtrair 7 dias
      const dataSeteDiasAtras = new Date(dataAtual.getTime() - 7 * 24 * 60 * 60 * 1000)
      // Obter o número de dias entre a data atual e a data passada como parâmetro
      const diferencaDias = Math.floor((dataAtual.getTime() - new Date(dia).getTime()) / 86400000)
      // Verificar se a diferença é maior que 7
      return diferencaDias < 7
    },
    async valuesPay (id) {
      try {
        const res = await HTTP.post(`services/app/Cashier/ShowClosingValues?idCashier=${id}`, {}, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
        const data = res.data.result
        this.$store.commit('valorID', data)
        // console.log(this.$store.getters.valuePays)
        this.id = id
        $('#modalCaixa').modal('show')
      } catch (error) {
        console.log(error, 'não carregou os valores')
      }
    },
    showModalDegustacao() {
      $("#modalDegustacao").modal("show");
    }
  }
}
</script>

<style scoped>
#monthTitle {
    color: #FFFF !important;
    font-size: 20px;
}

.titleMes {
    color: #e0a638;
}

.titleMesF1 {
    color: #FFFF !important;
}

.box {
    height: 80px;
    background-color: #357eb8;
    box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);

}

.dia {
    left: -23px;
}

.abertura {
    left: -20px;
}

.vendedor {
    left: -13px;
}

.fechamento {
    left: -35px;
}

.diferenca {
    left: 10px;
    text-align: center;
    /* margin: 0 auto; */
}

.btn_default {
    background-color: #e0a638;
    height: 40px;
    border: none;
}

.btn_default:hover {
    width: 100%;
    border-radius: 7px;
    font-weight: 500;
    border: none;
    background-color: #f38235;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    color: #ffff;
}

/* .containerPage {
  min-width: 1265px !important;
} */

.rowFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centersContainer {
    display: flex;
    justify-content: center;
}

.centers {
    margin: 0 auto 0 4px;
}

.centerFlex {
    display: flex;
    justify-content: flex-end;
}

.downloadCsv {
    border: none;
    background: none;
    color: #e0a638;
    font-size: 20px;
    height: 80px;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.downloadCsv:hover {
  color: #f38235;
}

.visibilityShow {
  visibility: hidden;
}

@media screen and (max-width:767px) {
    #boxInfoCont {
        margin-right: 0 !important;
    }

    .rowFlex {
        flex-wrap: wrap;
    }

    #rowFlexCont {
        margin-right: 0 !important;
    }

    .centers {
        flex-direction: column;
    }

    .infoValueCont {
        flex-direction: column;
    }

    #differenceCont {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .rowFlex .box {
        width: 48.8%;
        box-shadow: none !important;
    }

    #resultRow {
        margin-right: -11px !important;
    }

    .centersContainer {
        margin-right: 0 !important;
    }

    #resultRow .box {
        margin-right: 0 !important;
    }
}
</style>
