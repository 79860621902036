<template>
  <div class="caixaVue pt-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Caixa</p>
        </div>
    </div>
    <div class="container pages">
      <div
        class="col-md-12 pb-5"
        :style="load ? 'height:250px;' : 'height:100%;'"
      >
        <loader color="#e0a638" size="100" v-if="load" />
        <div class="col-md-12">
          <div class="flex ">
            <boxData
              service="Serviços Finalizados hoje "
              :task="valueFinish"
              :negative="false"
            />
            <boxData
              service="Serviços Cancelados hoje "
              :task="valueCancel"
              :negative="valueCancel > 5"
            />
          </div>
          <transition-group name="list-complete" tag="div">
            <caixaList
              v-for="(item, index) in caixa"
              :key="index + 1"
              :item.sync="item"
              class="list-complete-item"
              :class="index == caixa.length ? 'my-3' : ''"
              @getCaixa="getCaixa()"
            />
            <caixaList
              v-for="(item, index) in caixaAnterior"
              :key="index + 2"
              :item.sync="item"
              class="list-complete-item"
              :class="index == caixaAnterior.length ? 'my-3' : ''"
              @getCaixa="getCaixa()"
            />
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boxData from "@/components/caixaList/box_data/boxdata";
import caixaList from "@/components/caixaList/list";
import HTTP from "@/api_system";
import loader from "@/components/utils/_Load";
import { mapGetters } from "vuex";
export default {
  components: {
    boxData,
    caixaList,
    loader
  },
  data() {
    return {
      caixa: [],
      load: true,
      caixaAnterior: [],
      valueCancel: null,
      valueFinish: null,
      taskAll: [],
      pages: 1,
      sizeWindow: 0,
    };
  },
  watch: {
    pages(newValue) {
      if (newValue <= 5) {
        this.valuesTask();
      }
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.valuesTask();
    this.getCaixa();
    this.getCurrentBox()
  },
  methods: {
    getCurrentBox() {
      // console.log('sim')
      HTTP.get('services/app/Order/GetDetailsOfDay',{
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      }).then(res => {
        this.valueFinish = res.data.result.completedOrders;
        this.valueCancel = res.data.result.canceledOrders;
        console.log(res.data.result.completedOrders);
        console.log(res.data.result.canceledOrders);
      }).catch(err => console.log(err))

    },

    getCaixa() {
      HTTP.get("services/app/Cashier/GetCashierList", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.caixa = response.data.result.actualMonth;
          this.caixaAnterior = response.data.result.lastMonth;
          this.load = false;
          // console.log(this.caixa)
        })
        .catch(error => {
          this.load = false;

          if (this.$store.getters.getterToken) {
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
            });
          }
        });
    },
    async apis() {
      try {
        let result = await HTTP.get(
          "services/app/Order/GetListOrders?paginaAtual=" + this.pages,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        return result.data.result.orders;
      } catch (error) {
        this.$notify({
          group: "erros",
          type: "error",
          text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
        });
        console.log(error);
      }
    },
    valuesTask() {
      this.apis()
        .then(res => {
          res.forEach(item => {
            this.taskAll.push(item);
          });

          if (res.length > 0 && this.pages < 5) {
            this.pages++;
          }
          // else {
          //   this.valueFinish = 0;
          //   this.valueCancel = 0;
          //   if (this.taskAll.length > 0) {
          //     this.taskAll.forEach(item => {
          //       let today = new Date()
          //         .toJSON()
          //         .slice(0, 10)
          //         .replace(/-/g, "-");
          //       let dateCurrent = item.registerTime.substr(0, 10);

          //       if (dateCurrent === today) {
          //         if (item.status === 3) {
          //           this.valueFinish++; //console.log('finalizado')
          //         } else if (item.status === 1) {
          //           this.valueCancel++; // em aberto item.status === 2 ||
          //         }
          //       }
          //     }
          //     );
          //   }
          // }
        })
        .catch(err => {
          console.error(err);
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  },
  computed: {
    ...mapGetters(["get_message"])
  },
  watch: {
    get_message: function() {
      if (this.get_message == 5) {
        this.getCaixa();
      }
    }
  }
};
</script>

<style scoped>
.caixaVue {
  background: #2474b2;
}
.flex {
  display: flex;
}
.pages {
  min-width: 1487px !important;
  min-width: 100% !important;
}
@media screen and (max-width:767px){
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 59%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 26%;
  }
  .caixaVue{
    padding-top: 0!important;
  }
}
</style>
